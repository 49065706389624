// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-us-jsx": () => import("./../../../src/templates/about_us.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-base-jsx": () => import("./../../../src/templates/base.jsx" /* webpackChunkName: "component---src-templates-base-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-business-help-jsx": () => import("./../../../src/templates/business_help.jsx" /* webpackChunkName: "component---src-templates-business-help-jsx" */),
  "component---src-templates-domestic-help-jsx": () => import("./../../../src/templates/domestic_help.jsx" /* webpackChunkName: "component---src-templates-domestic-help-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../../../src/templates/homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-templates-sitemap-jsx": () => import("./../../../src/templates/sitemap.jsx" /* webpackChunkName: "component---src-templates-sitemap-jsx" */)
}

